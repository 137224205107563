import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getAuthorsRequest } from './api';

import { getAuthors, getAuthorsFailure, getAuthorsSuccess } from './actions';

function* getSaga(action: any) {
  try {
    const { payload } = action;

    const results: { authors: any } = yield call(getAuthorsRequest, payload);

    yield put(getAuthorsSuccess(results));
  } catch (error) {
    // console.log(error);
    yield put(getAuthorsFailure(error));
  }
}

export function* getAuthorsWatcher() {
  yield takeEvery(getAuthors.toString(), getSaga);
}
