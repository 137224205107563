import React from 'react';

import { useSelector } from 'react-redux';

import './Articles.css';
import { MoreArrow, ArticlePost, Promo } from 'components';
import { pathArticle, pathMaterials } from 'config';
import { articlesSelector } from 'store/ducks/homepage/selectors/articlesSelector';
import {
  selectPosition4Banner,
  selectPosition9Banner,
  selectPosition20Banner,
} from 'store/ducks/banners/selectors';

export function Articles() {
  let data = useSelector(articlesSelector);

  const position4Banner = useSelector(selectPosition4Banner);
  const position20Banner = useSelector(selectPosition20Banner);
  const position9Banner = useSelector(selectPosition9Banner);

  return (
    <>
      {position20Banner && (
        <Promo
          id="position20Banner"
          target={'position20Banner_' + position20Banner.id}
          type="articles"
          img={position20Banner.img}
          tag=""
          link={position20Banner.url}
          html={position20Banner.html}
        />
      )}
      <section
        className={`articles page__articles ${!position4Banner ? '_wide' : ''}`}
      >
        <h2 className="page-title articles__page-title">Аналитика</h2>
        <MoreArrow type="articles" link={pathArticle}>
          Все статьи
        </MoreArrow>
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return <ArticlePost hideText={true} key={item.id} {...item} />;
          })}
        {position4Banner && (
          <Promo
            id="position4Banner"
            target={'position4Banner_' + position4Banner.id}
            type="articles"
            img={position4Banner.img}
            tag=""
            link={position4Banner.url}
            html={position4Banner.html}
          />
        )}
      </section>
    </>
  );
}
