import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getTopMaterialsRequest = () => {
  const url = prefixApiUrl + 'api/taxonomy/7/posts/publication_at/DESC/1/4';

  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then(response => response.json())
    .then(data => {
      return data.posts;
    });
};
