import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getMaterialsRequest } from './api';

import {
  getMaterials,
  getMaterialsFailure,
  getMaterialsSuccess,
} from './actions';
import { Post } from 'types/models';

function* getSaga(action: {
  payload: {
    page: number;
    query: string;
    offset: string;
    sort_column: string;
  };
}) {
  try {
    const { payload } = action;
    const response: Post[] = yield call(getMaterialsRequest, payload);
    yield put(getMaterialsSuccess(response));
  } catch (error) {
    // console.log(error);
    yield put(getMaterialsFailure(error));
  }
}

export function* materialsWatcher() {
  yield takeEvery(getMaterials.toString(), getSaga);
}
