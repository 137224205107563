import { fork, all } from 'redux-saga/effects';

// import { aboutSaga } from './ducks/about/saga';
import { articleWatcher } from './ducks/article/saga';
import { commentsWatcher } from './ducks/comments/saga';
// import { newsfeedsSaga } from './ducks/newsfeeds/saga';
// import { homepageSaga } from './ducks/homepage/saga';
// import { newsSaga } from './ducks/news/saga';
import { routerSaga } from './ducks/router/saga';
import { newsWatcher } from '../components/News/saga';
import { personWatcher } from './ducks/person/saga';
import { homepageWatcher } from './ducks/homepage/saga';
import { getInitdataWatcher } from './ducks/app/saga';
import { newsfeedWatcher } from './ducks/newsfeed/saga';
import { navbarWatcher } from './ducks/menu/saga';
import { likesWatcher } from './ducks/likes/saga';
import { newsfeedsWatcher } from './ducks/newsfeeds/saga';
import { materialsWatcher } from './ducks/materials/saga';
import { bannersWatcher } from './ducks/banners/saga';
import { usersWatcher } from './ducks/users/saga';
import { addSubscribeWatcher } from './ducks/subscribe/saga';
import { authWatcher } from './ducks/auth/saga';
import { registerUserWatcher } from './ducks/registration/saga';
import { restoreUserWatcher } from './ducks/restore/saga';
import { getAuthorsWatcher } from './ducks/authors/saga';

export default function* rootSaga() {
  yield fork(newsWatcher);
  // fork(newsSaga),
  // fork(homepageSaga),
  yield fork(routerSaga);
  // fork(aboutSaga),
  // fork(newsfeedsSaga),
  yield fork(articleWatcher);
  yield fork(personWatcher);
  yield fork(homepageWatcher);
  yield fork(getInitdataWatcher);
  yield fork(newsfeedWatcher);
  yield fork(navbarWatcher);
  yield fork(likesWatcher);
  yield fork(commentsWatcher);
  yield fork(newsfeedsWatcher);
  yield fork(materialsWatcher);
  yield fork(bannersWatcher);
  yield fork(usersWatcher);
  yield fork(addSubscribeWatcher);
  yield fork(authWatcher);
  yield fork(registerUserWatcher);
  yield fork(restoreUserWatcher);
  yield fork(getAuthorsWatcher);
}
