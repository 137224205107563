import { createActions } from 'redux-actions';

const {
  getUser,
  getUserSuccess,
  getUserFailure,
  getUserIdle,
}: {
  getUser?: any;
  getUserSuccess?: any;
  getUserFailure?: any;
  getUserIdle?: any;
} = createActions(
  'GET_USER',
  'GET_USER_SUCCESS',
  'GET_USER_FAILURE',
  'GET_USER_IDLE'
);

export { getUser, getUserSuccess, getUserFailure, getUserIdle };
