import React, { useState, useEffect } from 'react';
import './Quiz.css';
import { Tag, Btn } from 'components';
export function Quiz(item: { title: string; content: any }) {
  let [quiz, setQuiz]: any = useState([]);

  useEffect(() => {
    if (item && item.content) {
      setQuiz(JSON.parse(item.content));
    }
  }, []);

  return (
    <div className="quiz others__quiz">
      <h4 className="quiz__title">{item.title}</h4>
      <form action="#" className="quiz__form">
        <ul className="quiz__list">
          {quiz.map((el: { title: string; id: string }) => (
            <li className="quiz__item">
              <label className="quiz__label">
                <input
                  className="quiz__input"
                  type="radio"
                  name="quiz"
                  value={el.id}
                />
                <span></span>
                {el.title}
              </label>
            </li>
          ))}
        </ul>
        <Btn type="quiz" mod="purple" handleClick={() => {}}>
          Отправить
        </Btn>
        <Tag type="" mod="">
          Опрос
        </Tag>
      </form>
    </div>
  );
}
