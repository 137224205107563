import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLike } from '../../store/ducks/likes/actions';
import cx from 'classnames';
import './Social.css';
type Props = {
  url: string;
  text: string;
  // postId: number;
  // like: any;
  // dislike: number;
};
// import { useSelector } from 'react-redux';

// import { ArticlePost, ArticleSubpost } from 'components';
// import { topMaterialsSelector } from 'store/ducks/homepage/selectors';

export function Social(props: Props) {
  const { url, text } = props;

  return (
    <div className="social article-footer__social">
      <ul className="social__list">
        {/* <li className="social__item">
                    <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                        target="_blank"
                        className="social__link"
                    >
                        <svg className="social__icon social__icon--facebook">
                            <use href="/img/sprite.svg#icons--facebook"></use>
                        </svg>
                    </a>
                </li> */}
        <li className="social__item">
          <a
            href={`https://vk.com/share.php?url=https://telesputnik.ru${url}`}
            target="_blank"
            className="social__link"
          >
            <svg className="social__icon social__icon--vk">
              <use href="/img/sprite.svg#icons--vk"></use>
            </svg>
          </a>
        </li>
        <li className="social__item">
          <a
            href={`https://connect.ok.ru/offer?url=https://telesputnik.ru${url}`}
            target="_blank"
            className="social__link"
          >
            <svg className="social__icon social__icon--odn">
              <use href="/img/sprite.svg#icons--odn"></use>
            </svg>
          </a>
        </li>
        {/* <li className="social__item">
                    <a href="#" target="_blank" className="social__link">
                        <svg className="social__icon social__icon--instagram">
                            <use href="/img/sprite.svg#icons--instagram"></use>
                        </svg>
                    </a>
                </li> */}
        {/* <li className="social__item">
                    <a
                        href={`https://twitter.com/intent/tweet?text=${text}&url=${url}`}
                        target="_blank"
                        className="social__link"
                    >
                        <svg className="social__icon social__icon--twitter">
                            <use href="/img/sprite.svg#icons--twitter"></use>
                        </svg>
                    </a>
                </li> */}
        <li className="social__item">
          <a
            href={`https://telegram.me/share/url?url=https://telesputnik.ru${url}&text=${text}`}
            target="_blank"
            className="social__link"
          >
            <svg className="social__icon social__icon--telegram">
              <use href="/img/sprite.svg#icons--telegram"></use>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
}
