import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getPersonRequest } from './api';

import { getPerson, getPersonFailure, getPersonSuccess } from './actions';

import { Post } from 'types/models';

function* getSaga() {
  try {
    const posts: Post[] = yield call(getPersonRequest);
    yield put(getPersonSuccess(posts));
  } catch (error) {
    // console.log(error);
    yield put(getPersonFailure(error));
  }
}

export function* personWatcher() {
  yield takeEvery(getPerson.toString(), getSaga);
}
