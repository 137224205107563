import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { Header, Footer, CookieAccept } from 'components';
import renderRoutes from 'routes';

import './App.css';
import { getNavbar } from 'store/ducks/menu/actions';
import { getInitdata } from 'store/ducks/app/actions';
import { getBanners } from 'store/ducks/banners/actions';
import { getToken } from 'config';
import { authSetToken } from 'store/ducks/auth/actions';
import ScrollTop from 'components/ScrollTop/ScrollTop';

function App(props: { location: string }) {
  const { location } = props;
  const dispatch = useDispatch();
  const statusInitData = useSelector(
    (state: { initdata: { status: string } }) => state.initdata.status
  );

  const statusBanners = useSelector(
    (state: { banners: { status: string } }) => state.banners.status
  );

  useEffect(() => {
    if (statusInitData == 'IDLE') {
      dispatch(getInitdata());
    }
    if (statusBanners == 'IDLE') {
      dispatch(getBanners());
    }
    if (getToken()) {
      dispatch(authSetToken(getToken()));
    }
  }, []);

  return (
    <div className="wrapper">
      <Header />
      <Switch>
        {renderRoutes(location).map(({ fetchData, ...routeProps }) => (
          <Route key={routeProps.path} {...routeProps} />
        ))}
      </Switch>
      <Footer />
      <ScrollTop />
      <CookieAccept />
    </div>
  );
}

const Component = hot(App);

export { Component as App };
