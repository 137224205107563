import { ReduxAction } from 'types';

// types
export const FETCH_ABOUT = '@@about/FETCH_ABOUT';
export const FETCH_ABOUT_SUCCESS = '@@about/FETCH_ABOUT_SUCCESS';
export const FETCH_ABOUT_FAILURE = '@@about/FETCH_ABOUT_FAILURE';

// action types
export type FetchAboutAction = ReduxAction<typeof FETCH_ABOUT>;
export type FetchAboutSuccessAction = ReduxAction<
  typeof FETCH_ABOUT_SUCCESS,
  any[]
>;
export type FetchAboutFailureAction = ReduxAction<
  typeof FETCH_ABOUT_FAILURE,
  string
>;

export type AboutActionTypes =
  | FetchAboutAction
  | FetchAboutSuccessAction
  | FetchAboutFailureAction;
