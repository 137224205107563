import { createSelector } from 'reselect';

import { Post, PostMetaItem, PostTaxonomyItem } from 'types/models';

import { getMetaField, getPreview } from 'config';
import moment from 'moment';

const topMaterials = (state: { homepage: { topMaterials: Post[] } }) =>
  state.homepage.topMaterials;

export const topMaterialsSelector = createSelector([topMaterials], items => {
  let data: { single: any; list: any[] } = { single: null, list: [] };

  if (items && items.length) {
    items.map((item: Post, index: number) => {
      if (item) {
        let dateArticle = item.created_at;
        if (item.publication_at) {
          dateArticle = item.publication_at;
        }

        let type = '',
          object = {
            id: (item.id && item.id) || 0,
            link: item.categories_path + '/' + item.slug,
            img: getPreview(
              item.get_post_types,
              item.announce_img,
              null,
              true,
              moment(dateArticle)
            ),
            title: item.title,
            date: moment(dateArticle).format('DD.MM.YYYY'),
            text: getMetaField('description', item.meta),
            tag:
              (item.get_categories &&
                item.get_categories.length > 0 &&
                item.get_categories[0].title) ||
              'Рубрика',
            type: type,
          };
        if (item.exclusive_material) {
          object = {
            ...object,
            tag: 'Эксклюзив',
          };
        }
        if (!index) {
          object = {
            ...object,
            tag: 'Главное',
            type: 'main',
          };
          if (item.exclusive_material) {
            object = {
              ...object,
              tag: 'Эксклюзив',
              type: 'main',
            };
          }
          data['single'] = object;
        } else {
          data['list'].push(object);
        }
      }
    });
  }

  return data;
});
