import * as React from 'react';
import cx from 'classnames';
import './VideoIcon.css';
type Props = {
  mod: string;
};

export function VideoIcon(props: Props) {
  const { mod } = props;
  return (
    <div className={cx('video-icon', mod && `video-icon--${mod}`)}>
      <div className="video-icon__triangle"></div>
    </div>
  );
}
