import * as React from 'react';

import { useSelector } from 'react-redux';

import * as bem from 'b_';
import { FooterInfo, FooterMenu, FooterSocial } from 'components';
import './Footer.css';
import { menuSelector } from 'store/ducks/menu/selector';
import { MenuItem } from 'types/models';
import { Link } from 'react-router-dom';
import { useIsDesktop } from '../../hooks';

const b = bem.with('footer');

export function Footer() {
  const currentYear = new Date().getFullYear();

  const menu = useSelector(menuSelector);

  const isDesktop = useIsDesktop();

  const ThemesList = () => {
    return (
      <div className="footer-menu footer__menu">
        <h4 className="footer-menu__title">Темы</h4>
        <ul className="footer-menu__list-themes">
          {menu.visible &&
            menu.visible.length &&
            menu.visible.map((item: MenuItem, index: number) => {
              return (
                <li className="footer-menu__item" key={index}>
                  <Link to={item.slug} className="footer-menu__link">
                    {item.title}
                  </Link>
                </li>
              );
            })}
          {menu.hidden &&
            menu.hidden.length > 0 &&
            menu.hidden.map((item: MenuItem, index: number) => {
              return (
                <li className="footer-menu__item" key={index}>
                  <Link to={item.slug} className="footer-menu__link">
                    {item.title}
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  const AboutList = () => {
    return (
      <div className="footer-menu footer__menu footer__menu--project">
        <h4 className="footer-menu__title">о проекте</h4>
        <div className="footer-menu__list-wrapper">
          {isDesktop ? (
            <>
              <ul className="footer-menu__list">
                <li className="footer-menu__item">
                  <Link to="/magazine/about" className="footer-menu__link">
                    Миссия
                  </Link>
                </li>
                <li className="footer-menu__item">
                  <Link to="/magazine/redaction" className="footer-menu__link">
                    Редакция
                  </Link>
                </li>
                <li className="footer-menu__item">
                  <Link
                    to="/magazine/redaction/policy"
                    className="footer-menu__link"
                  >
                    Редполитика
                  </Link>
                </li>
                <li className="footer-menu__item">
                  <a
                    href="/files/sout2018.pdf"
                    target="_blank"
                    className="footer-menu__link"
                  >
                    Оценка рабочих мест
                  </a>
                </li>
                <li className="footer-menu__item">
                  <a
                    href="/files/politicsConf.pdf"
                    target="_blank"
                    className="footer-menu__link"
                  >
                    Политика конфеденциальности
                  </a>
                </li>
              </ul>
              <ul className="footer-menu__list">
                <li className="footer-menu__item">
                  <a
                    href="/files/politicsPersInfo.pdf"
                    target="_blank"
                    className="footer-menu__link"
                  >
                    Политика обработки и защиты персональных данных
                  </a>
                </li>
                <li className="footer-menu__item">
                  <a
                    href="/files/requirementsImplInfo.pdf"
                    target="_blank"
                    className="footer-menu__link"
                  >
                    Сведения о реализуемых требованиях
                  </a>
                </li>
              </ul>
            </>
          ) : (
            <ul className="footer-menu__list">
              <li className="footer-menu__item">
                <Link to="/magazine/about" className="footer-menu__link">
                  Миссия
                </Link>
              </li>
              <li className="footer-menu__item">
                <Link to="/magazine/redaction" className="footer-menu__link">
                  Редакция
                </Link>
              </li>
              <li className="footer-menu__item">
                <Link
                  to="/magazine/redaction/policy"
                  className="footer-menu__link"
                >
                  Редполитика
                </Link>
              </li>
              <li className="footer-menu__item">
                <a
                  href="/files/sout2018.pdf"
                  target="_blank"
                  className="footer-menu__link"
                >
                  Оценка рабочих мест
                </a>
              </li>
              <li className="footer-menu__item">
                <a
                  href="/files/politicsConf.pdf"
                  target="_blank"
                  className="footer-menu__link"
                >
                  Политика конфеденциальности
                </a>
              </li>
              <li className="footer-menu__item">
                <a
                  href="/files/politicsPersInfo.pdf"
                  target="_blank"
                  className="footer-menu__link"
                >
                  Политика обработки и защиты персональных данных
                </a>
              </li>
              <li className="footer-menu__item">
                <a
                  href="/files/requirementsImplInfo.pdf"
                  target="_blank"
                  className="footer-menu__link"
                >
                  Сведения о реализуемых требованиях
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
    );
  };

  return (
    <footer className={b()}>
      <div className="footer__content container">
        {isDesktop ? (
          <>
            <FooterInfo />
            <ThemesList />
            <AboutList />
          </>
        ) : (
          <>
            <div className="footer-menu__tablet-lists-wrapper">
              <ThemesList />
              <AboutList />
            </div>
            <FooterInfo />
          </>
        )}
      </div>
      <a href="https://webmaster.yandex.ru/siteinfo/?site=https://telesputnik.ru">
        <img
          width="88"
          height="31"
          alt=""
          src="https://yandex.ru/cycounter?https://telesputnik.ru&theme=light&lang=ru"
        />
      </a>
    </footer>
  );
}
