import * as React from 'react';
import './Mission.css';
import cx from 'classnames';
import { Tag, MoreArrow } from 'components';

export function Mission() {
  return (
    <div className="mission">
      <h2 className="mission__title">Миссия</h2>
      <p className="mission__text">
        &laquo;Телеспутник&raquo; начал работу более 25&nbsp;лет
        назад&nbsp;&mdash; в&nbsp;1995 году рынку был представлен первый
        в&nbsp;стране специализированный ежемесячный журнал
        &laquo;Теле-Спутник&raquo;, посвященный актуальным вопросам
        и&nbsp;задачам развития рынка платного телевидения и&nbsp;спутниковых
        коммуникаций в&nbsp;России и&nbsp;в&nbsp;мире. По&nbsp;мере повсеместной
        диджитализации, мы&nbsp;перенесли накопленный опыт в&nbsp;онлайн-среду
        и&nbsp;сфокусировались на&nbsp;развитии одноименного интернет-портала:
      </p>
      <a href="#" className="mission__link">
        <svg className="mission__img">
          <use href="/img/sprite.svg#icons--link"></use>
        </svg>
        www.telesputnik.ru
      </a>
      <blockquote className="blockquote mission__blockquote">
        За&nbsp;четверть века &laquo;Телеспутник&raquo; зарекомендовал себя
        надежным и&nbsp;проверенным источником новостей,фактов, аналитики
        и&nbsp;экспертных публикаций о&nbsp;бизнесеи&nbsp;технологиях
        в&nbsp;области кабельного, спутниковогои&nbsp;онлайн-телевидения,
        а&nbsp;также видеопотребления, телекоммуникаций, смарт-технологий
        и&nbsp;цифровой трансформации.
      </blockquote>
      <p className="mission__text">
        В&nbsp;начале 2020 года &laquo;Телеспутник&raquo; объединился
        в&nbsp;медиагруппу с&nbsp;другим успешным и&nbsp;активно развивающимся
        проектом&nbsp;&mdash; TelecomDaily&nbsp;&mdash; для развития совместного
        конференционного бизнеса TMT Conference (www.tmtconferences.ru),
        а&nbsp;также использования новых возможностей, которые открывает
        синергия ведущих отраслевых медиаплощадок. Промежуточные результаты
        объединения подтвердили правильность выбранной стратегии. Кроме того,
        в&nbsp;год своего 25-летия &laquo;Телеспутник&raquo; значительно
        расширил тематику выпускаемых медиапродуктов: теперь она охватывает
        не&nbsp;только отрасли цифрового телевидения,
        но&nbsp;и&nbsp;медиапотребление, телекоммуникации, информационные
        технологии, а&nbsp;также цифровую экономику и&nbsp;трансформацию
        государства и&nbsp;бизнеса.
      </p>
      <p className="mission__text mission__text--big">
        &laquo;ТЕЛЕСПУТНИК&raquo; РАБОТАЕТ В&nbsp;ИНТЕРЕСАХ РЫНКА
        И&nbsp;СПОСОБСТВУЕТ ГАРМОНИЧНОМУ, ЗДОРОВОМУ И&nbsp;ВСЕСТОРОННЕМУ
        РАЗВИТИЮ КОНКУРЕНТНОЙ СРЕДЫ
      </p>
      <p className="mission__text">
        Мы&nbsp;создаем влиятельную российскую медиа-коммуникационную платформу
        о&nbsp;бизнесе и&nbsp;технологиях в&nbsp;области телекоммуникаций,
        медиапотребления и&nbsp;цифровой трансформации.
      </p>
      <div className="mission__goal">
        <div className="tag">Наша цель</div>
        Предоставить участникам рынка удобный медиа-инструмент, позволяющий
        эффективно решать задачи продвижения продуктов и&nbsp;технологий,
        формирования позитивного имиджа компаний и&nbsp;топ-менеджеров,
        а&nbsp;также повышения GR-значимости.
      </div>
      <p className="mission__text">
        В&nbsp;сфере наших интересов темы на&nbsp;стыке бизнеса
        и&nbsp;технологий, связанные с&nbsp;цифровой трансформацией государства
        и&nbsp;корпоративного сектора, цифровым телевидением, медиапотреблением,
        различными средами распространения видеосигнала, мультисервисными
        широкополосными сетями, интернетом вещей, электроникой
        и&nbsp;микроэлектроникой для &laquo;умных домов&raquo;
        и&nbsp;&laquo;умных городов&raquo;, а&nbsp;также построением
        и&nbsp;эксплуатацией информационно-коммуникационной инфраструктуры. Эту
        и&nbsp;другую актуальную для рынка повестку мы&nbsp;обсуждаем вместе
        с&nbsp;лидерами мнений и&nbsp;первыми лицами отрасли,
        а&nbsp;по&nbsp;результатам готовим актуальные публикации
        в&nbsp;различных форматах. Например, мобильная видеостудия
        &laquo;Телеспутник&raquo; работает на&nbsp;всех ключевых для отрасли
        телекоммуникаций деловых мероприятиях, а&nbsp;произведенные
        с&nbsp;ее&nbsp;помощью видеоинтервью выходят не&nbsp;только
        на&nbsp;нашем портале, но&nbsp;и&nbsp;во&nbsp;всех аккаунтах
        &laquo;Телеспутник&raquo; в&nbsp;социальных сетях.
      </p>
      <p className="mission__text mission__text--big">
        &laquo;ТЕЛЕСПУТНИК&raquo; ЭТО ИНФОРМАЦИОННОЕ ПРОСТРАНСТВО ДЛЯ
        ПРОФЕССИОНАЛОВ, РАБОТАЮЩИХ В&nbsp;СФЕРЕ ТЕЛЕКОММУНИКАЦИЙ, ЦИФРОВОГО
        ТВ&nbsp;И&nbsp;СМЕЖНЫХ ОБЛАСТЯХ&nbsp;&mdash; ИСТОЧНИК АКТУАЛЬНЫХ
        БИЗНЕС-НОВОСТЕЙ, ФАКТОВ, АНАЛИТИКИ И&nbsp;ЭКСПЕРТНЫХ МНЕНИЙ
      </p>
      <p className="mission__text">
        Мы&nbsp;стремимся оперативно реагировать на&nbsp;важные для отрасли
        события, предугадывать и&nbsp;формировать тренды, выступать
        коммуникационной площадкой для участников отрасли.
      </p>
    </div>
  );
}
