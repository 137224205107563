import { call, takeEvery, put, all } from 'redux-saga/effects';

import { addSubscribeRequest } from './api';

import {
  addSubscribe,
  addSubscribeFailure,
  addSubscribeSuccess,
} from './actions';

function* addSubscribeSaga(action: any) {
  try {
    const { payload } = action;

    const result: [] = yield call(addSubscribeRequest, payload);

    yield put(addSubscribeSuccess(result));
  } catch (error) {
    // console.log(error);
    yield put(addSubscribeFailure(error));
  }
}

export function* addSubscribeWatcher() {
  yield takeEvery(addSubscribe.toString(), addSubscribeSaga);
}
