import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const regRequest = (payload: {
  username: string;
  email: string;
  password: string;
  password_confirmation: string;
  name: string;
  surname: string;
  organization: string;
  field_of_activity: string;
}) => {
  let {
    username,
    email,
    password,
    password_confirmation,
    name,
    surname,
    organization,
    field_of_activity,
  } = payload;

  let url = prefixApiUrl + 'api/register';

  const headers = {
    // Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  const body = {
    username,
    email,
    password,
    password_confirmation,
    name,
    surname,
    organization,
    field_of_activity,
  };

  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      // console.log('error', error)
    });
};
