import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';
import { type } from 'os';

export const getCategoryDataIDRequest = () => {
  // let url = prefixApiUrl + 'api/taxonomyitem/posts/316/0/created_at/DESC/1/3';
  let url =
    prefixApiUrl + 'api/taxonomyitem/posts/21493/0/publication_at/DESC/1/3';

  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then(response => response.json())
      .then(data => {
        resolve(data.posts);
      });
  });
};
