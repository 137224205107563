import fetch from 'isomorphic-fetch';
import { prefixApiUrl } from 'config';

export const newsRequest = () => {
    const headers = {
        Authorization: 'Bearer {YOUR_JWT_TOKEN}',
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };
    const url = prefixApiUrl + 'api/post/index/title/asc/1/10';
    return fetch(url, {
        method: 'GET',
        headers,
    }).then(response => response.json());
};
