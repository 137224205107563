import * as React from 'react';
import { Link } from 'react-router-dom';
import './FooterInfo.css';
import { FooterSocial } from '../FooterSocial/FooterSocial';
import { useIsDesktop } from '../../hooks';

export function FooterInfo() {
  const isDesktop = useIsDesktop();

  const Logo = () => {
    return (
      <Link to={'/'} className="footer-info__logo">
        <img src="/img/icons/logo-short-white.svg" />
      </Link>
    );
  };

  const Copy = () => {
    return (
      <p className="footer__copy">
        © {new Date().getFullYear()} Телеспутник, Все&nbsp;права&nbsp;защищены
      </p>
    );
  };

  const Ad = () => {
    return (
      <a
        href="/files/mediakit.pdf"
        target="_blank"
        className="footer-menu__link"
      >
        <span className="footer-info__ad">Реклама</span>
      </a>
    );
  };

  if (isDesktop) {
    return (
      <div className="footer-info">
        <Logo />
        <Copy />
        <FooterSocial />
        <Ad />
      </div>
    );
  }

  return (
    <div className="footer-info">
      <Ad />
      <FooterSocial />
      <Logo />
      <Copy />
    </div>
  );
}
