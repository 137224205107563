import { createActions } from 'redux-actions';

const {
  restoreUser,
  restoreUserSuccess,
  restoreUserFailure,
  restoreUserIdle,
  renewUser,
  renewUserSuccess,
  renewUserFailure,
  renewUserIdle,
}: {
  restoreUser?: any;
  restoreUserSuccess?: any;
  restoreUserFailure?: any;
  restoreUserIdle?: any;
  renewUser?: any;
  renewUserSuccess?: any;
  renewUserFailure?: any;
  renewUserIdle?: any;
} = createActions(
  'RESTORE_USER',
  'RESTORE_USER_SUCCESS',
  'RESTORE_USER_FAILURE',
  'RESTORE_USER_IDLE',
  'RENEW_USER',
  'RENEW_USER_SUCCESS',
  'RENEW_USER_FAILURE',
  'RENEW_USER_IDLE'
);

export {
  restoreUser,
  restoreUserSuccess,
  restoreUserFailure,
  restoreUserIdle,
  renewUser,
  renewUserSuccess,
  renewUserFailure,
  renewUserIdle,
};
