import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getArticleIDRequest = (payload: any) => {
  const id: any = payload;
  let url = prefixApiUrl + 'api/post/' + id;

  return new Promise(resolve => {
    const headers = {
      Authorization: 'Bearer ',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then(response => response.json())
      .then(data => {
        resolve(data);
      });
  });
};
