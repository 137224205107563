// import { createActions } from 'redux-actions';

// const {
//     getArticle,
//     getArticleSuccess,
//     getArticleFailure,
//     getArticleIdle,
// }: {
//     getArticle?: any;
//     getArticleSuccess?: any;
//     getArticleFailure?: any;
//     getArticleIdle?: any;
// } = createActions(
//     'GET_ARTICLE',
//     'GET_ARTICLE_SUCCESS',
//     'GET_ARTICLE_FAILURE',
//     'GET_ARTICLE_IDLE'
// );

// export { getArticle, getArticleSuccess, getArticleFailure, getArticleIdle };

import { createActions } from 'redux-actions';

const { setLike, setLikeSuccess, setLikeFailure, setLikeIdle } = createActions(
  'SET_LIKE',
  'SET_LIKE_SUCCESS',
  'SET_LIKE_FAILURE',
  'SET_LIKE_IDLE'
);
// const { setListId } = createActions("SET_LIST_ID");

export { setLike, setLikeSuccess, setLikeFailure, setLikeIdle };
