import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
  getTopMaterialsRequest,
  getArticlesRequest,
  getQuizesRequest,
  getVideosRequest,
  getMaterialsLoadMoreRequest,
  getMaterialsLoadMoreFirstRequest,
  getHomeRequest,
  getEventsRequest,
  getCategoryDataIDRequest,
} from './api';

import {
  getHomepage,
  getHomepageFailure,
  getHomepageSuccess,
  getMaterialsLoadMore,
  getMaterialsLoadMoreSuccess,
  getMaterialsLoadMoreFailure,
} from './actions';

import { Post } from 'types/models';

function* getSaga() {
  try {
    const [
      topMaterials,
      articles,
      videos,
      events,
      persons,
      spb,
      articlesList,
    ]: [
      Post[],
      Post[],
      Post[],
      Post[],
      Post[],
      Post[],
      Post[],
      Post[]
    ] = yield all([
      call(getHomeRequest, { type: 'header_cards' }),
      call(getHomeRequest, { type: 'articles' }),
      // call(getQuizesRequest),
      call(getVideosRequest),
      call(getHomeRequest, { type: 'feeds' }),
      // call(getHomeRequest, { type: 'events' }),
      call(getHomeRequest, { type: 'persons' }),
      call(getCategoryDataIDRequest),
      call(getArticlesRequest),
    ]);

    yield put(
      getHomepageSuccess({
        topMaterials,
        articles,
        quizes: [],
        videos,
        events,
        persons,
        spb,
        articlesList,
      })
    );
  } catch (error) {
    // console.log(error);
    yield put(getHomepageFailure(error));
  }
}

function* getSagaMaterialsLoadMore(action: {
  payload: { page: number; offset: number };
}) {
  try {
    const { payload } = action;
    const materialsLoadMore: Post[] = yield call(
      getMaterialsLoadMoreRequest,
      payload
    );
    yield put(getMaterialsLoadMoreSuccess(materialsLoadMore));
  } catch (error) {
    // console.log(error);
    yield put(getMaterialsLoadMoreFailure(error));
  }
}

export function* homepageWatcher() {
  yield takeEvery(getHomepage.toString(), getSaga);
  yield takeEvery(getMaterialsLoadMore.toString(), getSagaMaterialsLoadMore);
}

// import { put, takeLatest, call } from 'redux-saga/effects';

// import * as actions from './actions';
// import * as types from './types';
// import * as service from './service';

// function* fetchHomepage() {
//     try {
//         const data = yield call(service.fetchHomepage);

//         yield put(actions.fetchHomepageSuccess(data));
//     } catch (error) {
//         yield put(actions.fetchHomepageError(error.message));
//     }
// }

// export function* homepageSaga() {
//     yield takeLatest(types.FETCH_HOMEPAGE, fetchHomepage);
// }
