import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
  getHomepage,
  getHomepageIdle,
  getHomepageSuccess,
  getMaterialsLoadMore,
  getMaterialsLoadMoreSuccess,
  getMaterialsLoadMoreIdle,
  getMaterialsLoadMoreClear,
} from './actions';
import { HomepageState } from './types';
import { StateStatus, Post } from 'types/models';

export const initialState: HomepageState = {
  status: 'IDLE',
  statusMaterialsLoadMore: 'IDLE',
  topMaterials: [],
  articles: [],
  articlesList: [],
  quizes: [],
  videos: [],
  events: [],
  persons: [],
  spb: [],
  materialsLoadMore: [],
  materialsLoadMorePaginare: null,
};

const status = handleActions<StateStatus>(
  {
    [getHomepage]: () => 'LOADING',
    [getHomepageSuccess]: () => 'SUCCESS',
    [getHomepageIdle]: () => 'IDLE',
  },
  initialState.status
);

const topMaterials = handleActions(
  {
    [getHomepageSuccess]: (
      state,
      action: { payload: { topMaterials: Post[] } }
    ) => action.payload.topMaterials,
  },
  initialState.topMaterials
);

const articles = handleActions(
  {
    [getHomepageSuccess]: (state, action: { payload: { articles: Post[] } }) =>
      action.payload.articles,
  },
  initialState.articles
);

const articlesList = handleActions(
  {
    [getHomepageSuccess]: (
      state,
      action: { payload: { articlesList: Post[] } }
    ) => action.payload.articlesList,
  },
  initialState.articlesList
);

const quizes = handleActions(
  {
    [getHomepageSuccess]: (state, action: { payload: { quizes: Post[] } }) =>
      action.payload.quizes,
  },
  initialState.quizes
);

const videos = handleActions(
  {
    [getHomepageSuccess]: (state, action: { payload: { videos: Post[] } }) =>
      action.payload.videos,
  },
  initialState.videos
);

const events = handleActions(
  {
    [getHomepageSuccess]: (state, action: { payload: { events: Post[] } }) =>
      action.payload.events,
  },
  initialState.events
);

const persons = handleActions(
  {
    [getHomepageSuccess]: (state, action: { payload: { persons: Post[] } }) =>
      action.payload.persons,
  },
  initialState.persons
);

const spb = handleActions(
  {
    [getHomepageSuccess]: (state, action: { payload: { spb: Post[] } }) =>
      action.payload.spb,
  },
  initialState.spb
);

const statusMaterialsLoadMore = handleActions<StateStatus>(
  {
    [getMaterialsLoadMore]: () => 'LOADING',
    [getMaterialsLoadMoreSuccess]: () => 'SUCCESS',
    [getMaterialsLoadMoreIdle]: () => 'IDLE',
  },
  initialState.statusMaterialsLoadMore
);

const materialsLoadMore = handleActions(
  {
    [getMaterialsLoadMoreSuccess]: (
      state,
      action: { payload: { posts: Post[] } }
    ) => {
      return [...state, ...action.payload.posts];
    },
    [getMaterialsLoadMoreClear]: () => [],
  },
  initialState.materialsLoadMore
);

const materialsLoadMorePaginare = handleActions(
  {
    [getMaterialsLoadMoreSuccess]: (
      state,
      action: {
        payload: { paginate: { current_page: any; total_pages: any } };
      }
    ) => {
      return action.payload.paginate;
    },
    [getMaterialsLoadMoreClear]: () => null,
  },
  initialState.materialsLoadMorePaginare
);

export const homepage = combineReducers({
  status,
  topMaterials,
  articles,
  articlesList,
  quizes,
  events,
  persons,
  videos,
  spb,
  statusMaterialsLoadMore,
  materialsLoadMore,
  materialsLoadMorePaginare,
});
