import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getQuizesRequest = () => {
  const url = prefixApiUrl + 'api/taxonomyitem/posts/12/id/DESC/1/100';

  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then(response => response.json())
    .then(data => {
      return [
        {
          id: 222,
          title: 'Какой процесс цифровизации произойдет в России раньше?',
          content:
            '[{"id":1,"title":"Введение электронного рубля"},{"id":2,"title":"Запуска 5G в «золотом диапазоне»"},{"id":3,"title":"Электронные бюллетени на выборах"},{"id":4,"title":"Интеграция «Госуслуг» с чипом гражданина"},{"id":5,"title":"Тотальное отслеживание автомобилей"}]',
        },
      ];
    });
};
