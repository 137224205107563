import * as React from 'react';
import './MenuIcon.css';

export function MenuIcon(props: any) {
  const { setShowNavbar, showNavbar } = props;

  return (
    <div
      className="menu-icon header__menu-icon"
      onClick={e => setShowNavbar(!showNavbar)}
    >
      <svg className="menu-icon__icon menu-icon__icon--mobile">
        <use href="/img/sprite.svg#icons--menu"></use>
      </svg>
      <img
        className="menu-icon__icon _close"
        src="/img/icon_close-navbar.svg"
        alt=""
      />
    </div>
  );
}
