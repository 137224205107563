import { call, takeEvery, put, all } from 'redux-saga/effects';

import { setLikesRequest } from './api';

// // import { getTaxonomyItemsPostRequest } from '../homepage/api';

import {
  setLike,
  setLikeSuccess,
  setLikeFailure,
  setLikeIdle,
} from './actions';

function* getSaga(action: any) {
  try {
    const { payload } = action;
    const result: any[] = yield call(setLikesRequest, payload);

    //         yield put(getArticleSuccess(result));
  } catch (error) {
    // console.log(error);
    yield put(setLikeFailure(error));
  }
}

export function* likesWatcher() {
  yield takeEvery(setLike.toString(), getSaga);
}
