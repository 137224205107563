import * as React from 'react';
import './AboutProjectContacts.css';
import { Btn } from 'components';
import cx from 'classnames';

export function AboutProjectContacts() {
  return (
    <div className="about-project-contacts about-project__contacts">
      <h2 className="about-project-contacts__title">Контакты</h2>
      <a
        href="https://yandex.ru/maps/-/CCUJE8UiXA"
        target="_blank"
        className="about-project-contacts__item"
      >
        <svg className="about-project-contacts__img">
          <use href="/img/sprite.svg#icons--location"></use>
        </svg>
        197198, Санкт-Петербург,
        <br /> ул. Новоладожская, д. 4, корп. 1, лит. П
      </a>
      <a
        href="tel:+78123326444"
        target="_blank"
        className="about-project-contacts__item"
      >
        <svg className="about-project-contacts__img">
          <use href="/img/sprite.svg#icons--phone"></use>
        </svg>
        +7 (812) 332-64-44
      </a>
      <a
        href="mailto:telesputnik@telesputnik.ru"
        target="_blank"
        className="about-project-contacts__item"
      >
        <svg className="about-project-contacts__img">
          <use href="/img/sprite.svg#icons--email"></use>
        </svg>
        telesputnik @telesputnik.ru
      </a>

      <div className="social social--about-project about-project-contacts__social">
        <ul className="social__list">
          {/* <li className="social__item">
                        <a href="#" className="social__link">
                            <svg className="social__icon social__icon--facebook">
                                <use href="/img/sprite.svg#icons--facebook"></use>
                            </svg>
                        </a>
                    </li> */}
          <li className="social__item">
            <a
              href="https://vk.com/telesputnik"
              target="_blank"
              className="social__link"
            >
              <svg className="social__icon social__icon--vk">
                <use href="/img/sprite.svg#icons--vk"></use>
              </svg>
            </a>
          </li>
          <li className="social__item">
            <a
              href="https://ok.ru/group/55408711696413"
              target="_blank"
              className="social__link"
            >
              <svg className="social__icon social__icon--odn">
                <use href="/img/sprite.svg#icons--odn"></use>
              </svg>
            </a>
          </li>
          {/* <li className="social__item">
                        <a href="#" className="social__link">
                            <svg className="social__icon social__icon--instagram">
                                <use href="/img/sprite.svg#icons--instagram"></use>
                            </svg>
                        </a>
                    </li> */}
          {/* <li className="social__item">
                        <a href="#" className="social__link">
                            <svg className="social__icon social__icon--twitter">
                                <use href="/img/sprite.svg#icons--twitter"></use>
                            </svg>
                        </a>
                    </li> */}
          <li className="social__item">
            <a
              href="https://telegram.me/telesputnik"
              target="_blank"
              className="social__link"
            >
              <svg className="social__icon social__icon--telegram">
                <use href="/img/sprite.svg#icons--telegram"></use>
              </svg>
            </a>
          </li>
          <li className="social__item">
            <a
              href="https://www.youtube.com/channel/UCUZZFbgpPMOdNF8-S6Kj4Cg"
              target="_blank"
              className="social__link"
            >
              <svg className="social__icon social__icon--youtube">
                <use href="/img/sprite.svg#icons--youtube"></use>
              </svg>
            </a>
          </li>
          <li className="social__item">
            <a
              href="http://www.telesputnik.ru/rss/"
              target="_blank"
              className="social__link"
            >
              <svg className="social__icon social__icon--rrs">
                <use href="/img/sprite.svg#icons--rrs"></use>
              </svg>
            </a>
          </li>
          <li className="social__item">
            <a
              href="mailto:telesputnik@telesputnik.ru"
              target="_blank"
              className="social__link"
            >
              <svg className="social__icon social__icon--mail">
                <use href="/img/sprite.svg#icons--mail"></use>
              </svg>
            </a>
          </li>
          <li className="social__item">
            <a
              href="https://dzen.ru/telesputnik"
              target="_blank"
              className="social__link"
            >
              <svg className="social__icon social__icon--zen">
                <use href="/img/sprite.svg#icons--zen"></use>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
