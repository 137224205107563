import React, { useState } from 'react';

import { BrowserRouter as Router, useHistory } from 'react-router-dom';

import './Search.css';

export function Search() {
  let history = useHistory();

  const [search, setSearch] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setSearch('');
    history.push('/materials?search=' + search);
  };

  return (
    <div className="search header__search">
      <form className="search__form" action="#" onSubmit={handleSubmit}>
        <input
          className="search__input search__input--mobile"
          type="text"
          placeholder="Поиск"
          value={search}
          onChange={e => {
            setSearch(e.target.value);
          }}
        />
        <button className="search__btn" type="submit">
          <svg className="search__icon">
            <use href="/img/sprite.svg#icons--search"></use>
          </svg>
        </button>
      </form>
    </div>
  );
}
