import * as React from 'react';
import cx from 'classnames';
import './Tag.css';
type Props = {
    children: React.ReactNode[] | React.ReactNode;
    type: string;
    mod: string;
};

export function Tag(props: Props) {
    const { mod, type, children } = props;
    return (
        <div
            className={cx(
                'tag',
                mod && `tag--${mod}`,
                type === 'video' && 'tag--red',
                children === 'Эксклюзив' && 'tag--red'
            )}
        >
            {children}
        </div>
    );
}
