import { createSelector } from 'reselect';

import { Post } from 'types/models';

import { getMetaField, getPreview } from 'config';
import moment from 'moment';

const articles = (state: { homepage: { articles: Post[] } }) =>
    state.homepage.articles;

export const articlesSelector = createSelector([articles], items => {
    let data: any[] = [];

    if (items && items.length) {
        items.map((item: Post, index: number) => {
            if (item) {
                let dateArticle = item.created_at;
                if (item.publication_at) {
                    dateArticle = item.publication_at;
                }

                let tag = '',
                    type = 'articles',
                    object = {
                        id: item.id,
                        link: item.categories_path + '/' + item.slug,
                        img: getPreview(
                            item.get_post_types,
                            item.announce_img,
                            null,
                            null,
                            moment(dateArticle)
                        ),
                        title: item.title,
                        date: moment(dateArticle)
                            .locale('ru')
                            .format('DD.MM.YYYY'),
                        text:
                            (item.meta &&
                                getMetaField('description', item.meta)) ||
                            '',
                        tag: tag,
                        type: type,
                    };
                if (item.exclusive_material) {
                    object = { ...object, tag: 'Эксклюзив' };
                }
                if (index == 1) {
                    object['type'] = 'articles-yellow';
                }

                data.push(object);
            }
        });
    }

    return data;
});
