import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getEditorialRequest = () => {
  // const url = prefixApiUrl + 'api/user/redaction/index';
  const url = prefixApiUrl + 'api/user/redaction/index/created_at/asc/1/10';

  const headers = {
    Authorization: 'Bearer ',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then(response => response.json())
    .then(data => data);
};
