import * as React from 'react';
import { CookieConsent } from 'react-cookie-consent';

export function CookieAccept() {
    return (
        <CookieConsent
            buttonText="СОГЛАСЕН"
            cookieName="AcceptionCookie"
            style={{
                background: '#282828',
                justifyContent: 'center',
                padding: '0 25%',
            }}
            contentStyle={{
                fontSize: '16px',
            }}
            buttonStyle={{
                background: 'f7f7f7',
                color: '#282828',
                fontSize: '16px',
                fontWeight: 'bold',
            }}
        >
            Используя этот сайт, Вы даете <a style={{ color:"#f7f7f7", fontWeight:"bold" }} href="/files/politicsPersInfo.pdf">согласие</a> на обработку персональных данных, в том числе cookie-файлов
        </CookieConsent>
    );
}

