import * as React from 'react';
// import './ArticlePostVideo.css';
import cx from 'classnames';
import { Tag, MoreArrow } from 'components';
import { Link } from 'react-router-dom';
type Props = {
  id: string;
  tag: string;
  title: string;
  img: string;
  link: string;
  type: string;
  isShowTag?: boolean;
  date: string;
};
export function ArticlePostSingle(props: Props) {
  const { id, link, img, tag, title, type, isShowTag, date } = props;
  return (
    <article
      className={cx('post-video', type === 'others' && 'others__quiz')}
      key={id}
    >
      <Link to={link} className="post-video__link">
        <img src={img} alt="img" className="post-video__img" />
        <div className="post-video__common">
          <h3
            className="post-video__title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h3>
          <div className="post-video__date">{date}</div>
        </div>
        {isShowTag && tag && (
          <Tag mod="" type="video">
            {tag}
          </Tag>
        )}
      </Link>
    </article>
  );
}
