import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useIsDesktop = (): boolean => {
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const changeIsDesktopState = () => {
      if (window.innerWidth < 992) {
        setIsDesktop(false);
      } else {
        setIsDesktop(true);
      }
    };

    changeIsDesktopState();

    window.addEventListener('resize', changeIsDesktopState);

    return () => {
      window.removeEventListener('resize', changeIsDesktopState);
    };
  }, []);

  return isDesktop;
};
