import * as React from 'react';
import './ArticlePost.css';
import cx from 'classnames';
import { Tag, MoreArrow } from 'components';
import { Link } from 'react-router-dom';
type Props = {
  id: string;
  tag: string;
  title: string;
  img: string;
  text: string;
  link: string;
  type: string;
  date: string;
  hideText: boolean;
};
export function ArticlePost(props: Props) {
  const { id, link, img, text, tag, title, type, date, hideText } = props;
  return (
    <article
      className={cx(
        'post',
        type && type === 'main' && 'main__post',
        type && type === 'articles' && 'post--articles articles__post',
        type &&
          type === 'articles-yellow' &&
          'post--articles post--articles-yellow articles__post'
      )}
      key={id}
    >
      <div className="post__content">
        <Link to={link} className="post__title">
          <h3
            className="post__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Link>
        <div className="post__date">{date}</div>
        {!hideText && (
          <p
            className="post__text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
        <MoreArrow type="" link={link}>
          Читать больше
        </MoreArrow>
      </div>
      <Link to={link} className="post__images">
        <img src={img} alt="img" />
      </Link>
      {tag && (
        <Tag mod="" type="">
          {tag}
        </Tag>
      )}
    </article>
  );
}
