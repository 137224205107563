import React, { useEffect, useState } from 'react';
import './Promotions.css';

export function Promotions() {
  const [listCourse, setListCourse]: [any, any] = useState();
  const getPercentageChange = (originalValue: number, finalValue: number) => {
    return (((finalValue - originalValue) / originalValue) * 100).toFixed(2);
  };

  useEffect(() => {
    const tickers = ['SBER', 'VKCO', 'YDEX', 'VEON-RX', 'RTKM', 'MTSS'];
    const names = {
      SBER: 'Сбербанк России',
      VKCO: 'VK',
      YDEX: 'Яндекс',
      'VEON-RX': 'ВымпелКом',
      RTKM: 'Ростелеком',
      MTSS: 'МТС',
    };
    const moexUrl =
      'https://iss.moex.com/iss/history/engines/stock/markets/shares/securities/TICKER.json?iss.meta=off&marketprice_board=1&sort_order=desc&limit=5&history.columns=CLOSE,SECID&iss.only=history';

    const fetchPromises = [];
    for (let i = 0; i < tickers.length; i++) {
      const ticker = tickers[i];
      const url = moexUrl.replace('TICKER', ticker);
      fetchPromises.push(
        fetch(url, {
          method: 'GET',
          redirect: 'follow',
        })
      );
    }
    Promise.all(fetchPromises).then(responses => {
      let result = [];
      let jsonPromises = [];
      for (let i = 0; i < responses.length; i++) {
        const r = responses[i];
        jsonPromises.push(r.json());
      }
      Promise.all(jsonPromises).then(rs => {
        for (let i = 0; i < rs.length; i++) {
          const r = rs[i];
          const delta = r.history.data[0][0] - r.history.data[1][0];
          const percentDelta = getPercentageChange(
            r.history.data[1][0],
            r.history.data[0][0]
          );
          result.push({
            ticker: r.history.data[0][1],
            name: names[r.history.data[0][1]],
            price: r.history.data[0][0],
            prev_price: r.history.data[1][0],
            delta: parseFloat(delta.toFixed(2)),
            percent_delta: parseFloat(percentDelta),
          });
        }
        let listCourseFirst: any = [],
          listCourseSecond: any = [];
        result.map(function (item: any) {
          let state = '';
          if (item.delta && item.delta[0] == '-') {
            state = 'promotions__pct--red';
          } else {
            state = 'promotions__pct--green';
          }
          if (
            item.ticker == 'SBER' ||
            item.ticker == 'VKCO' ||
            item.ticker == 'YNDX'
          ) {
            listCourseSecond.push({
              title: item.name,
              course: item.price,
              difference: item.delta,
              percent: item.percent_delta,
              position: state,
            });
          } else {
            listCourseFirst.push({
              title: item.name,
              course: item.price,
              difference: item.delta,
              percent: item.percent_delta,
              position: state,
            });
          }
        });
        if (localStorage.getItem('show-course') == '2') {
          setListCourse(listCourseSecond);
          localStorage.setItem('show-course', '1');
        } else {
          setListCourse(listCourseFirst);
          localStorage.setItem('show-course', '2');
        }
      });
    });
  }, []);

  const listWidth =
    (listCourse && listCourse.length > 0 && listCourse.length * 100) || 200;
  const animationSpeed =
    (listCourse && listCourse.length > 0 && listCourse.length * 2.5) || 8;

  return (
    <div className="promotions promotions--mobile header__promotions">
      <div
        className="promotions__list"
        style={{
          width: `${listWidth}%`,
        }}
      >
        <div style={{ animationDuration: `${animationSpeed}s` }}>
          {listCourse && listCourse.length > 0 && (
            <>
              {listCourse.map((item: any, index: number) => {
                let { title, course, position, difference, percent } = item;
                return (
                  <div className={`promotions__item`} key={index}>
                    <div className="promotions__currency">
                      {title}:
                      <span className="promotions__currency-number">
                        {course}
                      </span>
                      <span>&#8381;</span>
                    </div>
                    <div className={`promotions__pct ${position}`}>
                      <svg className="promotions__pct-icons">
                        <use href="/img/sprite.svg#icons--arrow-currency"></use>
                      </svg>
                      <span className="promotions__pct-number">
                        {difference}
                      </span>
                      <span className="promotions__pct-sign">&#8381;</span> (
                      <span className="promotions__pct-percent">{percent}</span>{' '}
                      %)
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div style={{ animationDuration: `${animationSpeed}s` }}>
          {listCourse && listCourse.length > 0 && (
            <>
              {listCourse.map((item: any, index: number) => {
                let { title, course, position, difference, percent } = item;
                return (
                  <div className={`promotions__item`} key={index}>
                    <div className="promotions__currency">
                      {title}:
                      <span className="promotions__currency-number">
                        {course}
                      </span>
                      <span>&#8381;</span>
                    </div>
                    <div className={`promotions__pct ${position}`}>
                      <svg className="promotions__pct-icons">
                        <use href="/img/sprite.svg#icons--arrow-currency"></use>
                      </svg>
                      <span className="promotions__pct-number">
                        {difference}
                      </span>
                      <span className="promotions__pct-sign">&#8381;</span> (
                      <span className="promotions__pct-percent">{percent}</span>{' '}
                      %)
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
