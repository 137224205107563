import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getNewsfeedRequest } from './api';

import { getNewsfeed, getNewsfeedFailure, getNewsfeedSuccess } from './actions';
import { Post } from 'types/models';

function* getSaga() {
  try {
    const response: Post[] = yield call(getNewsfeedRequest);
    yield put(getNewsfeedSuccess(response));
  } catch (error) {
    // console.log(error);
    yield put(getNewsfeedFailure(error));
  }
}

export function* newsfeedWatcher() {
  yield takeEvery(getNewsfeed.toString(), getSaga);
}
