import { createSelector } from 'reselect';

import { Post, PostMetaItem, PostTaxonomyItem } from 'types/models';

import { getMetaField, getPreview } from 'config';
import moment from 'moment';

const videos = (state: { homepage: { videos: Post[] } }) =>
  state.homepage.videos;

export const videosSelector = createSelector([videos], items => {
  let data: any[] = [];

  if (items && items.length) {
    items.map((item: Post, index: number) => {
      if (item) {
        let dateArticle = item.created_at;
        if (item.publication_at) {
          dateArticle = item.publication_at;
        }

        let tag = 'Видео',
          type = 'others',
          object = {
            id: item.id,
            link: item.categories_path + '/' + item.slug,
            img: getPreview(
              item.get_post_types,
              item.announce_img,
              null,
              true,
              moment(dateArticle)
            ),
            title: item.title,
            date: moment(dateArticle).format('DD.MM.YYYY'),
            text: getMetaField('description', item.meta),
            tag: tag,
            exclusive_material: item.exclusive_material,
            type: type,
          };

        data.push(object);
      }
    });
  }

  return data;
});
