import { createActions } from 'redux-actions';

const {
  registerUser,
  registerUserSuccess,
  registerUserFailure,
  registerUserIdle,
}: //registerSetToken,
{
  registerUser?: any;
  registerUserSuccess?: any;
  registerUserFailure?: any;
  registerUserIdle?: any;
  //registerSetToken?: any;
} = createActions(
  'REGISTER_USER',
  'REGISTER_USER_SUCCESS',
  'REGISTER_USER_FAILURE',
  'REGISTER_USER_IDLE'
  //'REGISTER_SET_TOKEN'
);

export {
  registerUser,
  registerUserSuccess,
  registerUserFailure,
  registerUserIdle,
  //registerSetToken,
};
