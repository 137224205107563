import React, { useState, useEffect } from 'react';
import './Subscription.css';
import './Checkbox.css';
import { Btn } from 'components';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { StateStatus } from 'types/models';
import { addSubscribe, addSubscribeIdle } from 'store/ducks/subscribe/actions';
import { getToken } from 'config';

type Props = {
  page: string;
  mod: string;
};

export function Subscription(props: Props) {
  const { page, mod } = props;

  const dispatch = useDispatch();

  const [disableSend, setDisableSend] = useState(true);

  const status = useSelector(
    (state: { subscribe: { status: StateStatus } }) => state.subscribe.status
  );
  const message = useSelector(
    (state: { subscribe: { message: string } }) => state.subscribe.message
  );

  const [formData, setFormData] = useState({
    email: '',
    categories: ['9'],
  });

  useEffect(() => {
    if (status == 'SUCCESS') {
      setTimeout(function () {
        setFormData({
          ...formData,
          email: '',
        });

        setTimeout(function () {
          dispatch(addSubscribeIdle());
        }, 5000);
      }, 2000);
    }
  }, [status]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (status == 'IDLE') {
      dispatch(addSubscribe(formData));
    }
  };

  const handleChange = (e: any) => {
    let fieldName = e.target.name,
      fieldType = e.target.type,
      fieldValue = e.target.value;

    if (fieldName == 'categories') {
      let checkedCategory: any = [];

      let categories = e.target.parentElement.parentElement.parentElement.querySelectorAll(
        '[name="categories"]:checked'
      );

      categories.forEach((item: any) => {
        checkedCategory.push(item.value);
      });

      fieldValue = checkedCategory;
    }

    setFormData(prevState => ({
      ...formData,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <section
      className={cx(
        'subscription page__subscription',
        page === 'about' && 'about-project__subscription',
        page === ' article-sidebar' && 'article-sidebar__subscription',
        mod === 'sidebar' && 'subscription--sidebar'
      )}
    >
      <h4 className="subscription__title">Подписка на&nbsp;рассылку</h4>
      <p className="subscription__text">
        Подпишитесь на рассылку, чтобы одним из первых быть в курсе новых
        событий
      </p>
      <form
        action="#"
        className="subscription__form"
        onSubmit={e => handleSubmit(e)}
      >
        <div className="subscription__categories">
          <label className="сheckbox _default">
            <input
              onChange={e => handleChange(e)}
              name="categories"
              type="checkbox"
              className="сheckbox__input"
              value={8}
            />
            <span className="сheckbox__icon"></span>
            Дайджест
          </label>
          <label className="сheckbox _default">
            <input
              onChange={e => handleChange(e)}
              name="categories"
              type="checkbox"
              className="сheckbox__input"
              value={9}
            />
            <span className="сheckbox__icon"></span>
            Ежедневная рассылка
          </label>
        </div>
        <div className="subscription__fields">
          {(!getToken() && (
            <input
              onChange={e => handleChange(e)}
              value={formData.email}
              name="email"
              className="subscription__input"
              type="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              required
              placeholder="Введите E-mail"
              disabled={(status == 'SUCCESS' && true) || false}
            />
          )) ||
            ''}
          {(status == 'SUCCESS' && message) || (
            <Btn
              disable={((status == 'LOADING' || disableSend) && true) || false}
              mod="purple"
              type="subscription"
              handleClick={() => {}}
            >
              Подписаться на рассылку
            </Btn>
          )}
        </div>

        <label className="confirm-subscribe">
          <input
            type="checkbox"
            checked={!disableSend}
            className="confirm-subscribe___input"
            onChange={e => {
              setDisableSend(!e.target.checked);
            }}
          />
          <span className="confirm-subscribe__text">
            Я даю <a href="/files/approval.pdf">согласие</a> на обработку
            персональных данных
          </span>
        </label>
      </form>
    </section>
  );
}
