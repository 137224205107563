import React from 'react';
import { PostMetaItem } from 'types/models';
import 'dotenv/config';
export const token =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvdGVsZXNwdXRuaWsuY28tZGQuZGV2XC9hcGlcL2xvZ2luIiwiaWF0IjoxNjUwOTU3ODA0LCJleHAiOjE2NTEzODk4MDQsIm5iZiI6MTY1MDk1NzgwNCwianRpIjoiUDlhZjFCZFRpMHJ1Z1pPQiIsInN1YiI6MTgsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjciLCJuYW1lIjoiU2VydmVyIiwicm9sZXMiOlsiXHUwNDEwXHUwNDM0XHUwNDNjXHUwNDM4XHUwNDNkXHUwNDM4XHUwNDQxXHUwNDQyXHUwNDQwXHUwNDMwXHUwNDQyXHUwNDNlXHUwNDQwIl19.uWK1ND8G4SFP0CCslcmBXT1WxbqQNBHtio_0nnyASPc';

export const pathMaterials = '/materials';
export const pathNewsfeed = pathMaterials + '/all';
export const pathPersony = pathMaterials + '/persony';
export const pathArticle = pathNewsfeed + '/article';
export const pathVideo = pathNewsfeed + '/video';
export const pathSpb = pathMaterials + '/spb';
export const prefixApiUrl =
    typeof window !== 'undefined' ? window.API_URL : process.env.API_URL;
export const prefixSiteUrl =
    typeof window !== 'undefined' ? window.SITE_URL : process.env.SITE_URL;

const noImagesPeriod = {
    start: '20220301',
    end: '20221001',
};

export const getPreview = (
    type: any[],
    announce_img: string,
    category?: any,
    isCard?: any,
    date?: any,
    categories_path?: any,
    allData?: any
) => {
    let imgUrl = null;
    let textDate;
    if (date) {
        textDate = date.format('YYYYMMDD');
    }
    let isFilterDate = false;
    if (
        categories_path &&
        categories_path.indexOf('/news') >= 0 &&
        textDate &&
        noImagesPeriod.start < textDate &&
        noImagesPeriod.end > textDate
    ) {
        isFilterDate = true;
    }
    if (announce_img && !isFilterDate) {
        if (announce_img[0] == 'h') {
            imgUrl = announce_img;
        } else {
            imgUrl = prefixApiUrl + 'storage/' + announce_img;
        }
    } else {
        if (type && type[0]) {
            if (type[0].slug == 'news') {
                imgUrl = '/img/article-header-bg.jpg';
            } else if (type[0].slug == 'video') {
                imgUrl = '/img/article-header-bg-video.jpg';
                if (isCard) {
                    imgUrl = '/img/article-preview-video.jpg';
                }
            } else if (type[0].slug == 'interview') {
                imgUrl = '/img/article-header-bg-interview.jpg';
            } else if (type[0].slug == 'article') {
                imgUrl = '/img/article-header-bg.jpg';
            } else if (type[0].slug == 'mneniya') {
                imgUrl = '/img/article-header-bg-interview.jpg';
            } else {
                imgUrl = 'https://via.placeholder.com/1000x375';
            }
        }
    }

    if (category) {
        if (category == 'persony' && !imgUrl) {
            imgUrl = '/img/article-header-bg-person.png';
        }
    }
    return imgUrl;
};

export const getMetaField = (key: string, fields: PostMetaItem[]) => {
    let value = '';
    if (fields && fields.length) {
        fields.forEach(item => {
            if (key == item.slug) {
                value = item.value;
            }
        });
    }
    if (value == '') {
        if (key == 'preview') {
            value = 'http://via.placeholder.com/370x220';
        }
        if (key == 'description') {
            value = '';
        }
    }
    return value;
};

export const setToken = (data: string) => {
    if (typeof window !== 'undefined') {
        const now = new Date();

        let timeLive = 24 * 60 * 60 * 1000;

        const result = {
            value: data,
            expiry: now.getTime() + timeLive,
        };
        localStorage.setItem('token', JSON.stringify(result));
    }
};

export const getToken = () => {
    if (typeof window !== 'undefined') {
        const data = localStorage && localStorage.getItem('token');
        if (!data) {
            return null;
        }
        const item = JSON.parse(data);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem('token');
            return null;
        }
        return item.value;
    }
};

export const removeToken = () => {
    if (typeof window !== 'undefined') {
        localStorage && localStorage.removeItem('token');
        return;
    }
};

export const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max);
};

export const hashCode = function (str: string) {
    var hash = 0,
        i,
        chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
    }
    return hash;
};

export const setView = (hash: string) => {
    if (typeof window !== 'undefined') {
        const now = new Date();

        let timeLive = 30 * 24 * 60 * 60 * 1000;

        const result = {
            value: hash,
            expiry: now.getTime() + timeLive,
        };
        localStorage.setItem('view-' + hash, JSON.stringify(result));
    }
};

export const getView = (hash: string) => {
    if (typeof window !== 'undefined') {
        const data = localStorage && localStorage.getItem('view-' + hash);
        if (!data) {
            return null;
        }
        const item = JSON.parse(data);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem('view-' + hash);
            return null;
        }
        return item.value;
    }
};

export const removeView = (hash: string) => {
    if (typeof window !== 'undefined') {
        localStorage && localStorage.removeItem('view-' + hash);
        return;
    }
};

export const setPromotions = (data: string) => {
    if (typeof window !== 'undefined') {
        const now = new Date();

        let timeLive = 24 * 60 * 60 * 1000;

        const result = {
            value: data,
            expiry: now.getTime() + timeLive,
        };
        localStorage.setItem('promotions', JSON.stringify(result));
    }
};

export const getPromotions = () => {
    if (typeof window !== 'undefined') {
        const data = localStorage && localStorage.getItem('promotions');
        if (!data) {
            return null;
        }
        const item = JSON.parse(data);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem('promotions');
            return null;
        }
        return item.value;
    }
};

export const removePromotions = () => {
    if (typeof window !== 'undefined') {
        localStorage && localStorage.removeItem('promotions');
        return;
    }
};
