import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getPerson, getPersonSuccess, getPersonIdle } from './actions';
import { PersonState } from './types';
import { StateStatus, Post } from 'types/models';

export const initialState: PersonState = {
  status: 'IDLE',
  posts: [],
};

const status = handleActions<StateStatus>(
  {
    [getPerson]: () => 'LOADING',
    [getPersonSuccess]: () => 'SUCCESS',
    [getPersonIdle]: () => 'IDLE',
  },
  initialState.status
);

const posts = handleActions(
  {
    [getPersonSuccess]: (state, action: { payload: Post[] }) => action.payload,
  },
  initialState.posts
);

export const person = combineReducers({
  status,
  posts,
});
