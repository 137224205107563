import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';

import {
  getCommentsArticle,
  getCommentsArticleSuccess,
  getCommentsArticleIdle,
} from './actions/commentsActions';

import {
  addCommentsArticle,
  addCommentsArticleSuccess,
  addCommentsArticleIdle,
} from './actions/addCommentsActions';

import { CommentsState, StateStatus } from './types';
// import { StateStatus } from '../../../types/models';
export const initialState: CommentsState = {
  status: 'IDLE',
  items: [],
  statusAdd: 'IDLE',
};

const status = handleActions<StateStatus>(
  {
    [getCommentsArticle]: () => 'LOADING',
    [getCommentsArticleSuccess]: () => 'SUCCESS',
    [getCommentsArticleIdle]: () => 'IDLE',
  },
  initialState.status
);

const statusAdd = handleActions<StateStatus>(
  {
    [addCommentsArticle]: () => 'LOADING',
    [addCommentsArticleSuccess]: () => 'SUCCESS',
    [addCommentsArticleIdle]: () => 'IDLE',
  },
  initialState.statusAdd
);

const items = handleActions(
  {
    [getCommentsArticleSuccess]: (state, action) => action.payload,
  },
  initialState.items
);

export const comments = combineReducers({
  status,
  items,
  statusAdd,
});
